import React from 'react'
import EnrollPage from './courses/EnrollPage';

const Contact = () => {
  return (
    <div>
   
      <section
        id="subheader"
        style={{
          backgroundImage: `url("/images/background/3c.png")`,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
        }} >
          <div className="center-y relative text-center" data-scroll-speed="4">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form
                    action="blank.php"
                    className="row"
                    id="form_subscribe"
                    method="post"
                    name="myForm"
                  >
                    <div className="col text-center">
                    
                      <h1>Get In Touch </h1>
                    </div>
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-highlight" className='no-top'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5  pb20 bg-color-3 text-white" style={{ color: "white" }} >
                              <div className="info-wrap w-100 p-lg-5 p-4">
                                <div className="dbox w-100 d-flex align-items-start">
                                  <div className="icon d-flex align-items-center justify-content-center">
                                      <span className="fa fa-map-marker fa-2x"></span>
                                  </div>
                                  <div className="text pl-3">
                                      <p><span>Address:</span> #8,2nd Floor, Tupe Residency, Thube  Park ,Beside Sancheti  Hospital, Behind Nexa Showroom,Shivaji Nagar, Pune</p>
                                  </div>
                                </div>
                              
                                <div className="dbox w-100 d-flex align-items-start">
                                  <div className="icon d-flex align-items-center justify-content-center">
                                      <span className="fa fa-phone fa-2x"></span>
                                  </div>
                                  <div className="text pl-3">
                                      <p><span>Phone:</span> <a tel="9975340919"> +91 9975340919</a></p>
                                  </div>
                                </div>

                                <div className="dbox w-100 d-flex align-items-start">
                                  <div className="icon d-flex align-items-center justify-content-center">
                                      <span className="fa fa-envelope fa-2x"></span>
                                  </div>
                                  <div className="text pl-3">
                                      <p><span>Email:</span> <a href="mailto:hr@sagveektechnologies.in"> hr@sagveektechnologies.in</a></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                                <EnrollPage></EnrollPage>
                            </div>
                        </div>
                  </div>

                </section>
    </div>
  )
}

export default Contact