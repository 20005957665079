import React from 'react'
import ServicesFAQ from './ServicesFAQ'
import ServicesIndexLayout from './ServicesIndexLayout'
const index = () => {
  return (
    <div>
     
     <section
      id="subheader"
      style={{
        backgroundImage: `url("/images/background/3c.png")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
      }}
    >
        <div className="center-y relative text-center" data-scroll-speed="4">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <form
                  action="blank.php"
                  className="row"
                  id="form_subscribe"
                  method="post"
                  name="myForm"
                >
                  <div className="col text-center">
                    <div className="spacer-single"></div>
                    <h1>Our Services</h1>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='no-top'>
				<div className="container">
              <ServicesIndexLayout />
				</div>      
      </section>

      <ServicesFAQ />

    </div>
  )
}

export default index