import React from "react";
import { Link } from "react-router-dom";
import coursesData from "./coursesData";

const CourseIndexLayout = () => {
  return (
    <div className="row">
      {coursesData.map((course) => (
        <div
          className="col-lg-4 col-md-6 mb10 wow fadeInUp"
          data-wow-delay="0s"
          key={course.slug} 
        >
          <div className="feature-box  f-boxed style-3" > 
            <Link to={`/courses/${course.slug}`}>
              
              <img src={`images/logos/${course.logo}`} className="img-fluid" alt="" />
            </Link>
            <div className="text">
              <Link to={`/courses/${course.slug}`}>
                <h4> {course.name}</h4>
              </Link>
            </div>
            <i className={`wm ${course.icon}`}></i>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CourseIndexLayout;
