import React from 'react'
import { Link } from 'react-router-dom'
import ServicesIndexLayout from './services/ServicesIndexLayout';
import CourseIndexLayout from './courses/CourseIndexLayout';
import GoogleReviews from './GoogleReviews';

const Home = () => {
    const apiKey = 'YOUR_GOOGLE_PLACES_API_KEY';
    const placeId = 'YOUR_GOOGLE_PLACE_ID';

  return (
    
        <div className="no-bottom no-top" id="content">
                <div id="top"></div>

                <section  className="full-height vertical-center"   style={{
        backgroundImage: `url("/images/background/1b.png")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
      }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 wow fadeInRight" data-wow-delay=".5s">
                                <h4></h4>
                                <div className="spacer-10"></div>
                                <h2>Welcome to <span className="id-color"> Sagveek Technologies</span> </h2>
                                <p className="lead">Sagveek Technologies is a team of techies and industry experts who understand the gap between education and industry requirements. We have more than 20 years of industry experience in training students and professionals on industry-specific courses.</p>
                                <div className="spacer-20"></div>
                                <Link className="btn-custom" to="/about">Know More</Link>
                                <div className="mb-sm-30"></div>
                            </div>

                            <div className="col-lg-6 offset-lg-1 wow fadeInLeft" data-wow-delay=".5s">
                                <img src="images/misc/3.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="section-highlight"  style={{ background:'#F5F9FD' }} data-bgcolor="#F5F9FD">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="text-center">
                                    <h1><span className="uptitle id-color">Our Services</span> </h1>
                                    <p className="lead"></p>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ServicesIndexLayout />
                        </div>
                    </div>
                </section>

                <section id="section-highlight" style={{ backgroundColor:'#fffcf1' }} >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="text-center">
                                    <h1><span className="uptitle id-color">Our Courses</span> </h1>
                                    <p className="lead"></p>
                                  
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <CourseIndexLayout />
                        </div>
                    </div>
                </section>

                <section id="section-fun-facts" className="pt20 pb20 text-light bg-color-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0s">
                                <div className="de_count">
                                    <h3 className="timer" data-to="3000" data-speed="3000">3000</h3>
                                    <span> Candidates Placed </span>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay=".25s">
                                <div className="de_count">
                                    <h3 className="timer" data-to="14" data-speed="3000">14</h3>
                                    <span>Our Courses</span>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                <div className="de_count">
                                    <h3 className="timer" data-to="10" data-speed="3000">8+</h3>
                                    <span>Our Services</span>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay=".75s">
                                <div className="de_count">
                                    <h3 className="timer" data-to="20" data-speed="3000">20</h3>
                                    <span>Years Experience</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="section-fun-facts" className="pt20 pb20 text-light bg-color-2">
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>
                                <GoogleReviews ></GoogleReviews>
                            </div>
                        </div>
                    </div>
                </section>
               
            </div>
    
  )
}

export default Home