import React from "react";
import { Link } from "react-router-dom";
import ServicesData from "./ServicesData";

const ServicesIndexLayout = () => {
  return (
    <div className="row">
      {ServicesData.map((service) => (

        <div className="col-lg-4 col-md-6 mb30" key={service.slug}>
							<div className="f-box f-icon-left f-icon-rounded">
                <Link to={`/services/${service.slug}`}><i className={` i-circle ${service.icon}`}></i></Link>
								<div className="fb-text f-box "><br/>
									<h5> {service.name} </h5>
								</div>
							</div>
						</div>

      ))}

    </div>
  );
};

export default ServicesIndexLayout;
