import React, { useState } from "react";
import Clients from "./Clients";

const About = () => {
 
  return (
    <div>
     <section
      id="subheader"
      style={{
        backgroundImage: `url("/images/background/3c.png")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
      }}
    >
        <div className="center-y relative text-center" data-scroll-speed="4">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <form
                  action="blank.php"
                  className="row"
                  id="form_subscribe"
                  method="post"
                  name="myForm"
                >
                  <div className="col text-center">
                    <div className="spacer-single"></div>
                    <h1>About Us</h1>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="no-top"
        data-bgimage="url(images/background/6.png) center"
      >
        <div className="container">
          <div className="row align-items-left">
           
            <div className="col-md-12" style={{aligh : 'left'}}>
              <p className="lead">
                At Sagveek Technologies, we believe in empowering individuals with the skills they need to thrive in the fast-paced world of technology. Our comprehensive courses are designed to equip you with practical knowledge and hands-on experience, preparing you for a successful career in the ever-evolving field of software development and data science.
              </p>
              <p className="lead">
                Sagveek Technologies is a team of techies and industry experts who understand the gap between education and industry requirements. We have more than 20 years of industry experience in training students and professionals on industry-specific courses.
              </p>
              <p className="lead">
                Sagveek Technologies was started as a step to bridge that  huge gap by providing executable education to the students in need. Whether you are a student looking to figure out the best  possible course to get an edge over your classmates, or someone looking for the best possible, lucrative job, we have courses designed just for that. Our curriculum is also very apt for professionals looking to take the next leap into bigger roles  within the organization, or simply looking for a job change. We make sure that the NO NONSENSE approach to industry-suited  courses equips you with the best possible way to take the jump.
              </p>
              <p className="lead">
              
                We at Sagveek Technologies constantly support aspiring
                students with high potential from B.E and M.E backgrounds, we
                offer them real-time experiences and opportunities to develop
                their engineering skills with hands-on applications.
              </p>
            </div>
          </div>

          

           {/* <Clients />
           */}
        </div>
      </section>
    </div>
  );
};

export default About;
