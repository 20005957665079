import React, { useState } from 'react'
import './WhatsAppOverlay.css'; 
import { Link } from 'react-router-dom';

const WhatsAppOverlay = () => {
    const [showOverlay, setShowOverlay] = useState(false);

    // const toggleOverlay = () => {
    //     setShowOverlay(!showOverlay);
    // };

  return (
    
    <div className="overlay-container">
      <a href="https://api.whatsapp.com/send?phone=919975340919&text=Hello, I came from your website&source=&data=#" className="whatsapp-toggle-button" title='Chat with us on Whatsapp' target="_blank">
        <img src="/whatsapp.png" height={50} width={50} alt="WhatsApp" />
      </a>
      {showOverlay && (
        <div className="whatsapp-overlay">
          
          
          <p>Click to chat on WhatsApp</p>
        </div>
      )}
    </div>

  )
}

export default WhatsAppOverlay