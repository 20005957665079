import React from 'react'

const Placements = () => {
  return (
    <div>
    <section
      id="subheader"
      style={{
        backgroundImage: `url("/images/background/3c.png")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
      }}
    >
        <div className="center-y relative text-center" data-scroll-speed="4">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <form
                  action="blank.php"
                  className="row"
                  id="form_subscribe"
                  method="post"
                  name="myForm"
                >
                  <div className="col text-center">
                    <div className="spacer-single"></div>
                    <h1>Placement And Certification</h1>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="no-top"
      >
        <div className="container">
          <div className="row align-items-left">
            
            <div className="col-md-12 " style={{aligh : 'left'}}>
              <p>
                 <b>Sagveek Technologies</b> is one amongst the leading coaching suppliers providing coaching & ASSURED PLACEMENT in varied technologies. we have a tendency to placed 3000+ candidates until currently. Our comprehensive coaching info, situation primarily based Job orientating coaching and quality helps the scholars to figure straight off once connection a corporation. until currently 2000+ Freshers area unit placed through Sagveek field choice (SCS Program)
              </p>
              <p>
                <h5>About Certification</h5>
                We do conduct a Mock test every  month and we have 100% Passing record for all the students who completed course from Sagveek technologies .
              </p>
             
            </div>
          </div>

          <div className="spacer-single"></div>

        </div>
      </section>
    </div>
  )
}

export default Placements