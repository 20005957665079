
import React from 'react';
//import axios from 'axios';

const GoogleReviews = ({ apiKey, placeId }) => {
    //const [reviews, setReviews] = useState([]);

    // useEffect(() => {
    //     // const fetchReviews = async () => {
    //     //     try {
    //     //         const response = await axios.get(`https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=reviews&key=${apiKey}`);
    //     //         setReviews(response.data.result.reviews);
    //     //     } catch (error) {
    //     //         console.error('Error fetching reviews:', error);
    //     //     }
    //     // };

    //     // fetchReviews();
    // }, [apiKey, placeId]);

  return (
    <div>
      <h2>Google Reviews</h2>
           <p />
      <img src="images/GoogleReviews.PNG" className="img-fluid" alt="" />
    </div>
  )
}

export default GoogleReviews