
import React from 'react';
import { useParams } from 'react-router-dom';
import coursesData from './coursesData';

function ModulePage() {
  const { courseSlug, moduleId } = useParams();
  const course = coursesData.find(course => course.slug === courseSlug);
  const module = course.modules.find(module => module.id.toString() === moduleId);

  if (!course || !module) {
    return <div>Module not found</div>;
  }

  return (
    <div>
      <h1>{course.name}</h1>
      <h2>{module.title}</h2>
      <p>{module.content}</p>
    </div>
  );
}

export default ModulePage;