import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer style={{height:'50px',backgroundColor:"#f5ba41"}}>
       
        <div className="container">
                    <div className="row">
                        <div className="col-md-8 sm-text-center">
                            <div> <h5 >Copyright &copy;2024 -  All rights reserved. Designed & Developed by Sagveek Technologies</h5></div>
                        </div>
                        <div className="col-md-4 text-md-right text-sm-left">
                            <div className="social-icons big">
                                <Link to="https://www.facebook.com/share/rmWgvDky6juJpXNP/?mibextid=qi2Omg"><i className="fa-brands fa-facebook fa-2x "></i></Link>
                                <Link to="#"><i className="fa-brands fa-twitter fa-2x"></i></Link>
                                <Link to="https://www.linkedin.com/company/sagveek-technologies/"><i className="fa-brands fa-linkedin fa-2x"></i></Link>
                                <Link to="#"><i className="fa-brands fa-google-plus fa-2x"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
    </footer>
        
  )
}

export default Footer