import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import CoursesFAQData from "./CourseFAQData";

function CourseFAQ() {
  const [selectedModule, setSelectedModule] = useState(null);

  const handleModuleClick = (moduleId) => {
    setSelectedModule(moduleId === selectedModule ? null : moduleId);
  };

  
  return (
    <div>
     
      <section className='no-top' >
        <div className="container">
          <div className="row">
            <div className="col-12">
            <h2> Frequently asked questions </h2>
              <div
                id="accordion-1"
                className="accordion accordion-style-1 text-left"
              >
                {CoursesFAQData.map((module) => (
                  <div className="card" key={module.id}>
                    <div
                      id="heading-a1"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h6 className="mb-0 font-weight-bold">
                        <a
                          className="d-block position-relative text-dark collapsible-link py-2"
                          onClick={() => handleModuleClick(module.id)}
                          aria-expanded={module.id === selectedModule}
                          aria-controls={`collapse-${module.id}`}
                        >
                          {module.id}. {module.question}
                        </a>
                      </h6>
                    </div>
                    <div  id={`collapse-${module.id}`}  className={`collapse ${
                          module.id === selectedModule ? "show" : "" }`}
                        aria-labelledby={`heading-${module.id}`}
                        data-parent="#accordion-1"
                      >
                      <div className="card-body">
                        {module.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>


          </div>
        </div>
       
      </section>
    </div>
  );
}

export default CourseFAQ;
