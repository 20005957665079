const CoursesFAQ = [
  
    {
        "id": 1,
        "question": "What programming languages do you offer training in?",
        "description": "We offer training in a wide range of programming languages including but not limited to Python, Java, JavaScript, C/C++, Ruby, Swift, and more."
    },
    {
        "id": 2,
        "question": "What are the prerequisites for enrolling in your courses?",
        "description": "Our courses cater to individuals with varying levels of experience, from beginners to advanced programmers. However, a basic understanding of computer science fundamentals is recommended for most of our courses."
    },
    {
        "id": 3,
        "question": "How are your courses structured?",
        "description": "Our courses are carefully designed to provide a comprehensive learning experience. They typically include a combination of lectures, hands-on coding exercises, projects, and assessments to ensure thorough understanding and practical application of concepts."
    },
    {
        "id": 4,
        "question": "Can I enroll in multiple courses simultaneously?",
        "description": "Yes, you can enroll in multiple courses simultaneously, depending on your availability and ability to manage workload. Our advisors can help you create a customized learning plan tailored to your goals."
    },
    {
        "id": 5,
        "question": "Do you offer any job placement assistance?",
        "description": "Yes, we provide job placement assistance to our students, including resume building, interview preparation, and connecting them with our network of hiring partners. However, job placement is not guaranteed."
    },
    {
        "id": 6,
        "question": "Are your courses suitable for working professionals?",
        "description": "Absolutely! We understand the busy schedules of working professionals, which is why we offer flexible course timings, including evening and weekend batches. Additionally, many of our courses are available online, allowing you to learn at your own pace."
    },
    {
        "id": 7,
        "question": "Can I get a certificate upon completing a course?",
        "description": "Yes, upon successful completion of a course, you will receive a certificate that demonstrates your proficiency in the respective programming language or technology. Our certificates are widely recognized by industry professionals and employers."
    },
    {
        "id": 8,
        "question": "Do you offer any discounts or scholarships?",
        "description": "We periodically offer discounts and scholarships based on various criteria such as merit, financial need, or group enrollments. Please contact our admissions team for more information on current offers."
    },
    {
        "id": 9,
        "question": "Are your instructors experienced industry professionals?",
        "description": "Yes, all our instructors are highly experienced industry professionals with extensive knowledge and expertise in their respective domains. They are dedicated to providing high-quality instruction and mentoring to our students."
    },
    {
        "id": 10,
        "question": "Can I schedule a demo class before enrolling?",
        "description": "Certainly! We offer demo classes for most of our courses, allowing you to get a feel for the teaching style, course content, and learning environment before making a commitment."
    },
    {
        "id": 11,
        "question": "What kind of support do you provide to students during the course?",
        "description": "We offer comprehensive support to our students throughout their learning journey. This includes access to dedicated teaching assistants, online forums for discussions and Q&A, and personalized feedback on assignments and projects."
    },
    {
        "id": 12,
        "question": "Are the course materials accessible online?",
        "description": "Yes, all course materials including lecture slides, code samples, and supplementary resources are available online through our learning management system. This allows students to review and revisit course content at their convenience."
    },
    {
        "id": 13,
        "question": "Do you offer any specialized tracks or concentrations within your programming courses?",
        "description": "Yes, we offer specialized tracks or concentrations in certain programming languages or domains such as web development, data science, machine learning, cybersecurity, and more. These tracks provide focused learning paths tailored to specific career goals."
    },
    {
        "id": 14,
        "question": "Are there any opportunities for networking with other students or industry professionals?",
        "description": "Yes, we organize networking events, workshops, and guest lectures where students can interact with industry professionals, alumni, and peers. These events provide valuable opportunities for learning, collaboration, and career advancement."
    },
    {
        "id": 15,
        "question": "Can I access course materials after completing the course?",
        "description": "Yes, you will have access to course materials for a certain period after completing the course, allowing you to review and reinforce your learning. Additionally, alumni may have access to exclusive resources and alumni networks."
    },
    {
        "id": 16,
        "question": "Are your courses updated regularly to reflect the latest trends and technologies?",
        "description": "Yes, we constantly update our course curriculum to keep pace with the rapidly evolving field of technology. Our instructors stay informed about the latest trends, best practices, and industry developments to ensure that our courses remain relevant and up-to-date."
    },
    {
        "id": 17,
        "question": "Do you offer any hands-on projects or real-world case studies?",
        "description": "Yes, our courses include hands-on projects and real-world case studies designed to provide practical experience and reinforce theoretical concepts. These projects are often based on industry scenarios and challenges, giving students valuable experience that they can showcase to potential employers."
    },
    {
        "id": 18,
        "question": "Can I get access to career counseling or mentorship services?",
        "description": "Yes, we offer career counseling and mentorship services to help students define their career goals, develop job search strategies, and navigate the job market. Our career advisors and mentors provide personalized guidance and support tailored to each student's needs."
    },
    {
        "id": 19,
        "question": "Is there a community or alumni network that I can join after completing the course?",
        "description": "Yes, we have a vibrant community and alumni network where students can stay connected, share resources, and collaborate on projects even after completing the course. This network provides ongoing support, professional development opportunities, and networking connections."
    },
    {
        "id": 20,
        "question": "What is your refund policy in case I need to withdraw from a course?",
        "description": "Our refund policy varies depending on the course and enrollment options. Please refer to our terms and conditions or contact our admissions team for detailed information about our refund policy."
    },
    {
        "id": 21,
        "question": "How successful are your students in securing job placements after completing the course?",
        "description": "We take pride in our high job placement rates, but actual placement outcomes can vary based on individual factors such as skills, experience, and job market conditions. Our job placement team works closely with students to maximize their chances of securing employment."
    },
    {
        "id": 22,
        "question": "Can you provide statistics or examples of recent graduates who have successfully transitioned into employment?",
        "description": "We regularly track the career progress of our graduates and are happy to provide statistics or testimonials showcasing their success stories. Please contact our job placement team for specific examples or data."
    },
    {
        "id": 23,
        "question": "What types of companies do your graduates typically find employment with?",
        "description": "Our graduates find employment opportunities with a wide range of companies, including startups, mid-sized companies, and Fortune 500 corporations. These companies operate in diverse industries such as technology, finance, healthcare, e-commerce, and more."
    },
    {
        "id": 24,
        "question": "Do you have partnerships or collaborations with companies for internships or job placements?",
        "description": "Yes, we have established partnerships and collaborations with various companies for internships, job placements, and recruitment events. These partnerships provide our students with access to exclusive job opportunities and networking connections."
    },
    {
        "id": 25,
        "question": "How does your job placement assistance process work?",
        "description": "Our job placement assistance process typically includes resume building, interview preparation, career counseling, networking events, and connecting students with our network of hiring partners. Our job placement team provides personalized support to help students navigate the job search process successfully."
    },
    {
        "id": 26,
        "question": "Do you offer any post-placement support or resources for alumni?",
        "description": "Yes, we offer post-placement support and resources for alumni, including career counseling, professional development workshops, networking events, and access to job postings. Our goal is to provide ongoing support to help alumni advance in their careers."
    },
    {
        "id": 27,
        "question": "Can I access job placement assistance even after completing the course?",
        "description": "Yes, our job placement assistance services are available to all current students as well as alumni. Whether you're actively searching for a job or considering a career change in the future, our job placement team is here to support you every step of the way."
    },
    {
        "id": 28,
        "question": "Are there any additional fees associated with job placement assistance?",
        "description": "No, job placement assistance is included as part of our course offerings, and there are no additional fees associated with accessing these services. We are committed to helping our students succeed in their careers without any extra financial burden."
    },
    {
        "id": 29,
        "question": "What is the average time it takes for graduates to secure employment after completing the course?",
        "description": "The time it takes for graduates to secure employment can vary depending on factors such as industry demand, location, and individual circumstances. Our job placement team works tirelessly to support students in their job search and help them find suitable employment opportunities as quickly as possible."
    },
    {
        "id": 30,
        "question": "Can I get personalized career advice based on my individual goals and circumstances?",
        "description": "Absolutely! Our job placement team provides personalized career advice and guidance tailored to each student's goals, skills, and circumstances. Whether you're looking for entry-level positions, career advancement opportunities, or freelance opportunities, we're here to help you navigate your career path effectively."
    },
    {
        "id": 31,
        "question": "Do you conduct mock technical interviews to help prepare students for real-world job interviews?",
        "description": "Yes, we offer mock technical interviews as part of our interview preparation services. These simulated interviews mimic real-world interview scenarios and help students practice their technical skills, problem-solving abilities, and communication skills in a supportive environment."
    },
    {
        "id": 32,
        "question": "How are mock interviews structured, and who conducts them?",
        "description": "Mock interviews are typically conducted by experienced industry professionals or our faculty members who are well-versed in the interview process. They are structured to simulate different types of technical interviews, such as whiteboard interviews, coding challenges, and behavioral interviews."
    },
    {
        "id": 33,
        "question": "Are mock interviews tailored to specific job roles or industries?",
        "description": "Yes, mock interviews can be tailored to specific job roles, industries, or companies based on students' preferences and career goals. Our goal is to provide relevant and targeted practice to help students succeed in their desired roles."
    },
    {
        "id": 34,
        "question": "Are there any resources or materials available to help students prepare for technical interviews?",
        "description": "Yes, we provide a variety of resources and materials to help students prepare for technical interviews, including coding practice problems, algorithm challenges, interview tips, and sample interview questions. These resources are available online through our learning management system."
    },
    {
        "id": 35,
        "question": "Can students request feedback or follow-up sessions after participating in mock interviews?",
        "description": "Yes, students can request feedback or follow-up sessions after participating in mock interviews. Our interviewers provide constructive feedback and guidance to help students identify areas for improvement and refine their interview skills."
    },
    {
        "id": 36,
        "question": "Do you offer practice tests or assessments to evaluate students' understanding of course material?",
        "description": "Yes, we offer practice tests, quizzes, and assessments throughout the course to evaluate students' understanding of course material and track their progress. These assessments help reinforce learning and prepare students for certification exams or real-world projects."
    },
    {
        "id": 37,
        "question": "Are there opportunities for peer-to-peer mock interviews or study groups?",
        "description": "Yes, we encourage students to participate in peer-to-peer mock interviews and study groups to practice interview techniques, discuss coding challenges, and share insights and tips. These collaborative learning experiences can enhance students' preparation and confidence."
    },
    {
        "id": 38,
        "question": "Are mock interviews and practice tests included in the course curriculum, or are they optional?",
        "description": "Mock interviews and practice tests are often included as optional components of the course curriculum, but participation is highly encouraged to maximize students' chances of success in job interviews. Our instructors and job placement team provide guidance on how to make the most of these opportunities."
    },
    {
        "id": 39,
        "question": "How can students schedule mock interviews or access practice tests?",
        "description": "Students can schedule mock interviews or access practice tests through our online platform or by contacting our job placement team. We offer flexible scheduling options to accommodate students' availability and preferences."
    },
    {
        "id": 40,
        "question": "Can students access recorded sessions or transcripts of mock interviews for review?",
        "description": "Yes, students can request recorded sessions or transcripts of mock interviews for review and self-assessment. These recordings provide valuable insights into students' performance and areas for improvement, allowing them to refine their interview skills over time."
    },
    {
        "id": 41,
        "question": "What is the total cost of the course, and are there any additional fees or expenses?",
        "description": "We strive to provide transparent pricing for our courses. The total cost typically includes tuition fees, course materials, and any applicable taxes. There are usually no hidden fees, but it's important to clarify this upfront."
    },
    {
        "id": 42,
         "question": "Do you offer installment plans or payment options for tuition fees?",
        "description": "Yes, we understand that financing education can be a concern for some students, which is why we offer flexible payment options and installment plans. Our advisors can work with you to create a payment plan that fits your budget and needs."
    },
    {
        "id": 43,
        "question": "What are the installment plan options available, and how does the payment schedule work?",
        "description": "Our installment plans vary depending on the course and duration. Typically, payments are divided into equal installments over the duration of the course. Our advisors can provide detailed information about the installment options available for your chosen course."
    },
    {
        "id": 44,
        "question": "Is there an additional fee or interest charged for opting for installment payments?",
        "description": "There may be a nominal administrative fee or interest charged for opting for installment payments, depending on the terms of the installment plan. Our advisors can provide clarity on any additional fees associated with installment payments."
    },
    {
        "id": 45,
        "question": "Can I pay the tuition fees in full upfront, and are there any discounts available for doing so?",
        "description": "Yes, you have the option to pay the tuition fees in full upfront, and in some cases, we offer discounts or incentives for doing so. Our admissions team can provide information about any available discounts or promotions for early payment."
    },
    {
        "id": 46,
        "question": "Are there any scholarships or financial aid options available for students with financial need?",
        "description": "Yes, we offer scholarships or financial aid options for students with demonstrated financial need or exceptional merit. These scholarships may cover partial or full tuition fees, depending on the criteria and availability."
    },
    {
        "id": 47,
        "question": "Do you have a refund policy for tuition fees in case of withdrawal from the course?",
        "description": "Yes, we have a refund policy for tuition fees in case of withdrawal from the course. The refund policy typically varies depending on factors such as the timing of the withdrawal and the course duration. Our advisors can provide detailed information about our refund policy."
    },
    {
        "id": 48,
        "question": "Can I transfer my enrollment to a different course or batch if needed?",
        "description": "Yes, we understand that circumstances may change, and we are flexible with course transfers whenever possible. Our advisors can assist you in transferring your enrollment to a different course or batch, subject to availability and eligibility."
    },
    {
        "id": 49,
        "question": "Are there any discounts or incentives for referring friends or family to enroll in your courses?",
        "description": "Yes, we often offer referral discounts or incentives for students who refer friends or family members to enroll in our courses. Our advisors can provide information about our referral program and any incentives available."
    },
    {
        "id": 50,
        "question": "How can I get assistance or clarification regarding fees and payment options?",
        "description": "Our admissions team is here to assist you with any questions or concerns regarding fees and payment options. You can reach out to us via phone, email, or in-person consultation to discuss your specific needs and preferences."
    }
];

export default CoursesFAQ;