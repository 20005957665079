import React from 'react'
import {NavLink} from 'react-router-dom';
const Navbar = () => {
  return (

    <ul id="mainmenu">
        <li> <NavLink to='/'>Home</NavLink> </li>
        <li> <NavLink to='/about'>About Us</NavLink> </li>
        <li>
            <NavLink to='/services'>Services</NavLink> 
            <ul>
                <li><NavLink to='services/web-development'> Web Development</NavLink>  </li>
                <li><NavLink to='services/website-maintenance'>Website Maintinance </NavLink>  </li>
                <li><NavLink to='services/digital-marketing'>Digital Marketing </NavLink>  </li>
                <li><NavLink to='services/seo'>Social Media Optimization </NavLink>  </li>
                <li><NavLink to='services/android-application-development'>Android Application Development</NavLink>  </li>
                <li><NavLink to='services/ecommerse-solutions'>E-Commerce Solutions</NavLink>  </li>
                <li><NavLink to='services/brochure-designing'>Brochure Designing</NavLink>  </li>
                <li><NavLink to='services/logo-design'>Logo Design</NavLink>  </li>
            </ul> 
        </li>
        <li>
            <NavLink to='/courses'>Courses</NavLink> 
             <ul>
                <li><NavLink to='courses/data-science-with-python'>Data Science With Python</NavLink>  </li>
                <li><NavLink to='courses/advanced-python'>Advanced Python</NavLink>  </li>
                <li><NavLink to='courses/deploma-in-mean-stack'>Deploma In MEAN Stack</NavLink>  </li>
                <li><NavLink to='courses/deploma-in-java'>Deploma In Java</NavLink>  </li>
                <li><NavLink to='courses/ui-ux-dev'>UI / UX</NavLink>  </li>
                <li><NavLink to='courses/javascript'>Javascript</NavLink>  </li>
                <li><NavLink to='courses/reactjs'>ReactJS</NavLink>  </li>
                <li><NavLink to='courses/angular-js'>Angular JS</NavLink>  </li>
                <li><NavLink to='courses/aws'>AWS</NavLink>  </li>
                <li><NavLink to='courses/devops'>DevOps</NavLink>  </li>
                {/* <li><NavLink to='/mlops'>MLOps</NavLink>  </li> */}
                <li><NavLink to='courses/robotics-process-automation-development'>Robotics Process Automation Development (RPA)</NavLink>  </li>
                <li><NavLink to='courses/power-bi'>Power BI</NavLink>  </li>
                <li><NavLink to='courses/c-and-cpp-programming'>C/C++ Programming</NavLink>  </li>
                <li><NavLink to='courses/software-testing'> Software Testing</NavLink> </li>

            </ul>  

         </li>
        <li> <NavLink to='/placements-and-certification'>Placements and Certification</NavLink> </li>
        {/* <li> <NavLink to='/faqs'>FAQ</NavLink> </li> */}
        <li> <NavLink to='/contact-us'>Contact Us</NavLink> </li>
    </ul>
    
  )
}

export default Navbar