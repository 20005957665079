import React from 'react'
import CourseIndexLayout from './CourseIndexLayout'
import CourseFAQ from './CourseFAQ'
const index = () => {
  return (
    <div>
     
     <section
      id="subheader"
      style={{
        backgroundImage: `url("/images/background/3c.png")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
      }}
    >
        <div className="center-y relative text-center" data-scroll-speed="4">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <form
                  action="blank.php"
                  className="row"
                  id="form_subscribe"
                  method="post"
                  name="myForm"
                >
                  <div className="col text-center">
                    <div className="spacer-single"></div>
                    <h1>Courses</h1>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section className='no-top pt20 pb20 text-dark' style={{ backgroundColor:'#fffcf1' }}>
				<div className="container">

              <CourseIndexLayout></CourseIndexLayout>
          
				</div>      
      </section>
      
      <CourseFAQ></CourseFAQ>
        
    </div>
  )
}

export default index