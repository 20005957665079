const servicesData = [
    {
      name: "Web Development",
      slug: "web-development",
      description:'Crafting dynamic and engaging digital experiences tailored to your unique needs. Our web development services encompass everything from custom website design and development to e-commerce solutions and web application development. We leverage the latest technologies and best practices to bring your vision to life, ensuring seamless functionality and stunning aesthetics across all devices.',
      icon:'fa-solid fa-desktop',
      modules: [
        {
          id: 1,
          title: "Chapter 1: Jupyter Notebook",
          content: [
            { description: 'Introduction' },
            { description: 'Basic Commands' },
            { description: 'Keyboard Shortcut and Magic Functions' }             
            ]
        },
      ]
    },
    {
      name: "Website Maintenance",  
      slug: "website-maintenance",
      description:'Keep your online presence polished and functional with our expert website maintenance services. From routine updates and security patches to content management and performance optimization, we ensure your website stays secure, up-to-date, and running smoothly, allowing you to focus on your business goals.',
      icon:'fa-solid fa-list-check',

    },
    {
      name: "Digital Marketing",
      slug: "digital-marketing",
      description:"Elevate your online presence and reach your target audience with our comprehensive digital marketing solutions. From strategic planning and campaign management to SEO optimization, social media marketing, and content creation, we utilize data-driven approaches to maximize your brand's visibility and drive meaningful engagement. Let us help you navigate the digital landscape and achieve your marketing objectives effectively.",
      icon:'fa-solid fa-lightbulb',
     
    },
    {
      name: "Social Media Optimization",
      slug: "seo",
      description:'Unlock the full potential of your social media presence with our expert Social Media Optimization (SMO) services. We tailor strategies to enhance your brand visibility, engagement, and reach across various platforms. From profile optimization and content creation to audience targeting and analytics, we optimize every aspect to boost your social media performance and drive meaningful connections with your audience.',
      icon:'fa-solid fa-globe',
    },
    {
      name: "Android Application Development",
      slug: "android-application-development", 
      description:'Empower your business with our Android application development expertise. We specialize in crafting intuitive, user-friendly mobile experiences tailored to your unique needs. From concept to deployment, we handle every aspect of development, including UI/UX design, coding, testing, and deployment. Whether you need a consumer-facing app or an enterprise solution, we deliver high-quality Android applications that drive engagement and deliver results.',
      icon:'fa-brands fa-android',

    },
    {
      name: "E-Commerce Solutions",
      slug: "ecommerse-solutions",

      description:"Transform your online business with our comprehensive e-commerce solutions. We offer end-to-end services tailored to your specific requirements, including website development, payment gateway integration, inventory management, and user experience optimization. Whether you're launching a new e-commerce store or looking to enhance an existing platform, we leverage cutting-edge technology and industry best practices to drive sales, increase conversions, and deliver a seamless shopping experience for your customers",
      icon:'fa-solid fa-cart-plus',
    },
    {
      name: "Brochure Designing",
      slug: "brochure-designing",
      description:"Enhance your brand's impact with our expert brochure design services. We specialize in crafting captivating brochures that effectively convey your message and leave a lasting impression. From concept development to graphic design, our team meticulously tailors each element to align seamlessly with your brand identity and marketing goals. Let us help you make a bold statement and stand out from the competition with our professional brochure designs.",
      icon:'fa-solid fa-object-group',

    },
    {
      name: "Logo Design",
      slug: "logo-design",
      description:"Define your brand identity with our professional logo design services. We specialize in creating unique and memorable logos that reflect your company's values and personality. From initial concept sketches to final vectorized designs, our team works closely with you to ensure every element resonates with your target audience. Let us help you leave a lasting impression and establish a strong visual identity with our custom logo designs",
      icon:'fa-solid fa-pen-fancy',

    },
  ];
  
  export default servicesData;