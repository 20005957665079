import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ServicesData from "./ServicesData";

function ServicesPage() {
  const [selectedModule, setSelectedModule] = useState(null);

  const handleModuleClick = (moduleId) => {
    setSelectedModule(moduleId === selectedModule ? null : moduleId);
  };

  const { servicesSlug } = useParams();
  const services = ServicesData.find((services) => services.slug === servicesSlug);

  if (!services) {
    return <div>

<section
        id="subheader"
        style={{
          backgroundImage: `url("/images/background/3c.png")`,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="center-y relative text-center" data-scroll-speed="4">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <form
                  action="blank.php"
                  className="row"
                  id="form_subscribe"
                  method="post"
                  name="myForm"
                >
                  <div className="col text-center">
                    <div className="spacer-single"></div>
                    <h3>Service not found</h3>
                   
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>;
  }

  return (
    <div>
      <section
        id="subheader"
        style={{
          backgroundImage: `url("/images/background/3c.png")`,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="center-y relative text-center" data-scroll-speed="4">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <form
                  action="blank.php"
                  className="row"
                  id="form_subscribe"
                  method="post"
                  name="myForm"
                >
                  <div className="col text-center">
                    <div className="spacer-single"></div>
                    <h3>{services.name}</h3>
                    <h6>{services.description}</h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{
          backgroundImage: `url("/images/background/7.png")`,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
        }} >
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<h2><span class="id-color">Call us</span> for further information. Sagveek Technologies customer care is here to help you <span class="id-color">anytime</span>. </h2>
							<p className="lead">We're available for 24 hours!</p>
						</div>
						
						<div className="col-md-6 text-lg-center text-sm-center">
							<div claclassNamess="phone-num-big">
								<i className="fa fa-phone id-color"></i>
								<span className="pnb-text">
									Call Us Now
								</span>
								<span className="pnb-num">
                  997 534 0919
								</span>
							</div>
							<a href="tel:9975340919" className="btn-custom capsule med">Contact Us</a>
						</div>
					</div>
				</div>
			</section>
      
    </div>
  );
}

export default ServicesPage;
