import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import coursesData from "./coursesData";
import CourseFAQ from "./CourseFAQ";
import EnrollPage from "./EnrollPage";

function CoursePage() 
{
  const [selectedModule, setSelectedModule] = useState(null);

  const handleModuleClick = (moduleId) => {
    setSelectedModule(moduleId === selectedModule ? null : moduleId);
  };

  const { courseSlug } = useParams();
  const course = coursesData.find((course) => course.slug === courseSlug);

  if (!course) {
    return (
      <div>
        <section
          id="subheader"
          style={{
            backgroundImage: `url("/images/background/3c.png")`,
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
          }}
        >
          <div className="center-y relative text-center" data-scroll-speed="4">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form
                    action="blank.php"
                    className="row"
                    id="form_subscribe"
                    method="post"
                    name="myForm"
                  >
                    <div className="col text-center">
                      <div className="spacer-single"></div>
                      <h3>Course not found</h3>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div>
      <section
        id="subheader"
        style={{
          backgroundImage: `url("/images/background/3c.png")`,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="center-y relative text-center" data-scroll-speed="4">
          <div className="container">
            <div className="row row-eq-height">
            <div className="col-md-3" 
              // style={{
              //   backgroundImage: `url("/images/logos/${course.logo}")`,
              //   width: "80%",
              //   height: "80%",
              //   backgroundSize: "cover",
              // }}
              > <div className="spacer-single"></div>
               <img src={`/images/logos/${course.logo}`} height="80" width="80" className="img-fluid" alt="" />
              
            </div>
              <div className="col-md-8 ">
                <form
                  action="blank.php"
                  className="row"
                  id="form_subscribe"
                  method="post"
                  name="myForm" 
                >
                  <div className="col text-center">
                    
                    <h3>{course.name}</h3>
                    <h6>{course.description}</h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section-fun-facts"
        className="pt20 pb20 text-light bg-color-2 no-top"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2 wow fadeInUp" data-wow-delay="0s">
              <div className="de_count">
                <h4 data-speed="3000">{course.duration}</h4>
                <span>Course Duration</span>
              </div>
            </div>

            <div
              className="col-lg-2 col-md-2 wow fadeInUp"
              data-wow-delay=".25s"
            >
              <div className="de_count">
                <h4>{course.mode}</h4>
                <span>Mode Of Tranning</span>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay=".5s"
            >
              <div className="de_count">
                <h4>
                  {" "}
                  <a href={`tel:9975340919`}> 9975340919 </a>
                </h4>
                <span>Contact Number</span>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay=".75s"
            >
              <div className="de_count">
                <h4>
                  <a href={`mailto:hr@sagveektechnologies.in`}>
                    {" "}
                    hr@sagveektechnologies.in{" "}
                  </a>
                </h4>
                <span>Email</span>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-2 wow fadeInUp"
              data-wow-delay=".75s"
            >
              <div className="de_count">
                <button
                  type="button"
                  className="btn btn-danger left"
                  data-toggle="modal"
                  data-target="#couseModal"
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="spacer-single"></div>
      <section className="no-top">
        <div className="container">
          <div className="row">
            <div className=" col-md-8 col-lg-8 col-sm-12">
              <div
                id="accordion-1"
                className="accordion accordion-style-1 text-left"
              >
                {course.modules.map((module) => (
                  <div className="card" key={module.id}>
                    <div
                      id="heading-a1"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h6 className="mb-0 font-weight-bold">
                        <a
                          className="d-block position-relative text-dark collapsible-link py-2"
                          onClick={() => handleModuleClick(module.id)}
                          aria-expanded={module.id === selectedModule}
                          aria-controls={`collapse-${module.id}`}
                        >
                          {module.title}
                        </a>
                      </h6>
                    </div>
                    <div
                      id={`collapse-${module.id}`}
                      className={`collapse ${
                        module.id === selectedModule ? "show" : ""
                      }`}
                      aria-labelledby={`heading-${module.id}`}
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        {module.content.map((item, index) => (
                          <li key={index}>{item.description}</li>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div id="sidebar" className="col-md-4 col-lg-4 col-sm-12">
              <div className="widget widget-post">
                <h4>Our Courses &nbsp;</h4>
                <div className="small-border"></div>
                <ul>
                  {coursesData.map((course) => (
                    <li>
                      <Link to={`/courses/${course.slug}`}> {course.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CourseFAQ></CourseFAQ>

      <div
        className="modal fade text-dark"
        id="couseModal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="couseModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="couseModalLabel">
                Enroll Now
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                  <EnrollPage />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoursePage;
