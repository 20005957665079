import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//pages
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Placements from "./pages/Placements";
import FAQ from './pages/FAQ';
import Services from "./pages/services/index";
import ServicesPage from "./pages/services/ServicesPage";

import Courses from "./pages/courses/index";

import CoursePage from './pages/courses/CoursePage';
import ModulePage from './pages/courses/ModulePage';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
        <Route index  element={<Home />} />
        <Route path="about" element={<About />} />

        <Route path="services" element={<Services />} />
        <Route path="services/:servicesSlug" element={<ServicesPage />} />

        <Route path="courses" element={<Courses />} />
        <Route path="courses/:courseSlug" element={<CoursePage />} />
        <Route path="courses/:courseSlug/modules/:moduleId" element={<ModulePage />} />
        
        <Route path="placements-and-certification" element={<Placements />} />
        {/* <Route path="faqs" element={<FAQ />} /> */}
        <Route path="contact-us" element={<Contact />} />

    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
