import "./App.css";
import { Link, Outlet } from "react-router-dom";
// components

import Navbar from "./components/Navbar";
import Footer from "./pages/Footer";
import WhatsAppOverlay from "./pages/WhatsAppOverlay";

function App() {
  return (
    <div className="App">
      <div>
        <div id="wrapper">
          <header className="header-light transparent scroll-light">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <div className="align-self-center header-col-left">
                      <div id="logo">
                        <Link to="/" style={{display:'flex'}}>
                          <img alt="" src="../images/logo.gif" style={{marginTop:'10px',width:"70px",height:"60px"}} /> <h4 className="id-color" style={{ marginTop:'30px' }}>Sagveek Technologies</h4>
                        </Link>
                      </div>
                    </div>
                    <div className="align-self-center ml-auto header-col-mid">
                      <Navbar></Navbar>
                    </div>
                    <div className="align-self-center ml-auto header-col-right">
                      <span id="menu-btn"></span>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="no-bottom no-top" id="content">
            <div id="top"></div>
                <Outlet />
          </div>
          <WhatsAppOverlay></WhatsAppOverlay>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
