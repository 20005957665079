import React, { useState } from "react";
import axios from 'axios';

const EnrollPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!formData.name || !formData.email || !formData.phone || !formData.message) 
        {
            setError('Please fill in all fields.');
            return;
        }

        try 
        {
          fetch('http://www.sagveektechnologies.in/api/email.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            // Handle success or error response
            setError('Thank you for contacting us!');
        })
        .catch(error => {
            console.error('Error:', error);
            setError('Failed to send email.');
            // Handle error
        });

            // const response = await axios.post('', formData);
            // if (response.data === 'sent') {
            //     // Handle success
            // } else {
            //     setError('Failed to send email.');
            // }
        } 
        catch (error) 
        {
            setError('Failed to send email.');
        }
    };

  return (
    <div>
      <form
        name="contactForm"
        id="contact_form"
        class="form-border"
         onSubmit={handleSubmit}
      >
        <div class="field-set">
          <input
            type="text"
            name="name"
            id="name"
            class="form-control"
            placeholder="Your Name"
            value={formData.name} onChange={handleChange}
          />
        </div>

        <div class="field-set">
          <input
            type="text"
            name="email"
            id="email"
            class="form-control"
            placeholder="Your Email"
            value={formData.email} onChange={handleChange}
          />
        </div>

        <div class="field-set">
          <input
            type="text"
            name="phone"
            id="phone"
            class="form-control"
            placeholder="Your Phone"
            value={formData.phone} onChange={handleChange}
          />
        </div>

        <div class="field-set">
          <textarea
            name="message"
            id="message"
            class="form-control"
            placeholder="Your Message"
            value={formData.message} onChange={handleChange}
          ></textarea>
        </div>

        <div class="spacer-half"></div>
        {error && <p>{error}</p>}
        <div id="submit">
          <input
            type="submit"
            id="send_message"
            value="Submit Form"
            class="btn btn-custom"
          />
        </div>
      </form>
    </div>
  );
};

export default EnrollPage;
